<template>
	<div class="layout-topbar">
		<router-link class="layout-topbar-logo" to="/">
<!--			<img alt="Logo" src="/images/dialog-logo.png" />-->
			<span style="color:#ec2127">DialX</span>
		</router-link>
<!--		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">-->
<!--			<i class="pi pi-bars"></i>-->
<!--		</button>-->

		<button v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}"
			class="p-link layout-topbar-menu-button layout-topbar-button">
			<i class="pi pi-ellipsis-v"></i>
		</button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <!--      <li>-->
      <!--        <Button aria-haspopup="true" class="p-button-text" @click="moveToDashboard()">-->
      <!--          <i class="pi pi-phone"></i>&nbsp;<span>Dashboard</span>-->
      <!--        </Button>-->
      <!--      </li>-->
      <li>
        <Button aria-haspopup="true" class="p-button-help p-button-text" @click="moveToHelp()">
          <i class="pi pi-question-circle"></i>&nbsp;<span>How to use DialX service</span>&nbsp;&nbsp;<i
            class="pi pi-external-link" style="font-size: 0.75rem"></i>
        </Button>
      </li>
      <li>
        <Button aria-haspopup="true" class="p-button-warning p-button-text" href="#" @click="logout()">
          <i class="pi pi-sign-out"></i>&nbsp;<span>Sign Out</span>
        </Button>
      </li>
    </ul>
	</div>
</template>

<script>

import {useStore} from "./store/Store";
import {useRouter} from "vue-router";

export default {
  setup() {
    const store = useStore()
    const router = useRouter();
    const logout = (event) => {
      const logoutOptions = {};

      store.keycloak.logout(logoutOptions).then((success) => {
        console.log("--> log: logout success ", success);
      }).catch((error) => {
        console.log("--> log: logout error ", error);
      });
    }

    const moveToHelp = (event) => {
      // router.push("/help-web")
      window.open('https://dialx.ideabiz.lk/helpw', '_newtab');
    }
    const moveToDashboard = (event) => {
      router.push("/")
    }
    return {
      store,
      logout,
      moveToHelp,
      moveToDashboard,
      items: [
        {
          label: 'Sign Out',
          icon: 'pi pi-sign-out',
          command: () => {
            var logoutOptions = {
              // redirectUri : urls.cns
            };

            store.keycloak.logout(logoutOptions).then((success) => {
              console.log("--> log: logout success ", success);
            }).catch((error) => {
              console.log("--> log: logout error ", error);
            });
          }
        },
      ]
    }
  }
}
</script>