import {defineStore} from 'pinia';
import {BASE_URL, MIN_DATE_ADD} from "../constants";
// const
// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application


export const useStore = defineStore('main', {
    state: () => {
        return {
            keycloak: null,
            displayForm: false,
            displayTAndC: false,
            virtualNumberConfigList: [],
            virtualNumbersList: [],
            primaryNumberList: [],
            configId: "",
            primaryNumber: "",
            primaryNumberErrorMessage: "",
            virtualNumber: "",
            from: "",
            minDate: new Date().addDays(MIN_DATE_ADD),
            noOfDays: "",
            terms: false,
            savePressed: false,
            listLoading: false,
            saving: false,
            mainError: "",
            deactivating: false,
            renewing: false,
            toast: null,
        };
    },
    getters: {
        isInvalidPrimaryNumber() {
            let val = false;
            if (isNaN(this.primaryNumber)) {
                val = true;
                this.primaryNumberErrorMessage = "Number only.";
            } else if (this.primaryNumber.length != 10) {
                val = true;
                this.primaryNumberErrorMessage = "Number must have 10 digits.";
            }
            return val && this.savePressed;
        },
        isInvalidVirtualNumber() {
            let val = false;
            if (!this.virtualNumber) {
                val = true;
            }
            return val && this.savePressed;
        },
        isInvalidFrom() {
            let val = false;
            if (!this.from) {
                val = true;
            }
            return val && this.savePressed;
        },
        isInvalidNoOfDays() {
            let val = false;
            if (!this.noOfDays) {
                val = true;
            }
            return val && this.savePressed;
        },
        isInvalidTerms() {
            console.info("this.terms");
            console.info(this.terms);
            return !this.terms && this.savePressed;
        },
    },
    actions: {
        async fetchVirtualNumberConfigList() {
            this.virtualNumberConfigList = [];
            this.listLoading = true;
            fetch(BASE_URL + '/virtual_number_configurations/list', {
                headers: {
                    'authorization': 'Bearer ' + this.keycloak.token
                }
            }).then(res => res.json())
                .then(values => {
                    values.forEach(value => {
                        value.from = new Date(value.from);
                        this.virtualNumberConfigList.push(value);
                    });
                    this.listLoading = false;
                    this.fetchVirtualNumbersList();
                    this.fetchPrimaryNumberList();
                }).catch(reason => {
                console.log(reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });
        },
        async fetchVirtualNumbersList() {
            fetch(BASE_URL + '/virtual_number_configurations/available/number/list', {
                headers: {
                    'authorization': 'Bearer ' + this.keycloak.token
                }
            }).then(res => res.json()).then(value => {

                return value;
            }).then(value => {
                this.virtualNumbersList = [];
                value.forEach(value => {
                    // console.log(value)
                    //
                    this.virtualNumbersList.push({name: value.number, code: value.number});
                });
            }).catch(reason => {
                console.log(reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });
        },
        fetchVirtualNumbersOne() {
            this.virtualNumber = "loading...";
            fetch(BASE_URL + '/virtual_number_configurations/available/number/one/' + this.primaryNumber, {
                headers: {
                    'authorization': 'Bearer ' + this.keycloak.token
                }
            }).then(res => res.json()).then(value => {
                if (value.length > 0) {
                    this.virtualNumber = value[0].number;
                } else {
                    this.virtualNumber = "Numbers are not available, Please check later.";
                }
            }).catch(reason => {
                console.log("reason:- " + reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });
        },
        async fetchPrimaryNumberList() {
            fetch(BASE_URL + '/virtual_number_configurations/available/primary/list', {
                headers: {
                    'authorization': 'Bearer ' + this.keycloak.token
                }
            }).then(res => res.json())
                .then(value => {
                    if (value.status == "SUCCESSFUL") {
                        this.primaryNumberList = [];
                        value.data.filter(item => item.status === 'Active')
                            .map(item => this.primaryNumberList.push({name: item.msisdn, code: item.msisdn}));
                    } else {
                        console.log("FAIL-----------------");
                        this.mainError = value.message;
                        u
                        this.saving = false;
                        this.displayForm = false,
                            this.toast.add({
                                severity: 'error',
                                summary: 'Error',
                                detail: value.message + ' Please contact support.',
                                life: 30000
                            });
                    }
                })
                .catch(e => {
                    console.error('Error when fetching primary number list ', e);
                    this.primaryNumberList = [];
                }).catch(reason => {
                console.log(reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });
        },
        open() {
            this.configId = "";
            this.primaryNumber = "";
            this.primaryNumberErrorMessage = "";
            this.virtualNumber = "Please select primary number.";
            this.from = "";
            this.noOfDays = "";
            this.terms = false;
            this.savePressed = false;
            this.displayForm = true;
            this.mainError = "";
            // this.fetchVirtualNumbersOne()

        },
        edit(val) {
            console.log("edit");
            console.log(val.noOfDays);
            this.configId = val.id;
            this.primaryNumber = val.primaryNumber;
            this.primaryNumberErrorMessage = "";
            this.virtualNumber = val.virtualNumber;
            this.from = val.from;
            this.noOfDays = val.noOfDays;
            this.terms = false;
            this.savePressed = false;
            this.virtualNumbersList.push({name: val.virtualNumber, code: val.virtualNumber});
            this.displayForm = true;
            this.renewing = true;

        },
        save() {
            console.info("asasa");
            console.info(this.primaryNumber);
            console.info(this.virtualNumber.code);
            console.info(this.from);
            console.info(this.noOfDays);
            console.info(this.terms);
            this.savePressed = true;

            if (this.isInvalidPrimaryNumber ||
                this.isInvalidVirtualNumber ||
                this.isInvalidFrom ||
                this.isInvalidNoOfDays ||
                this.isInvalidTerms) {
                return;
            }
            this.saving = true;
            this.mainError = "";
            fetch(BASE_URL + '/virtual_number_configurations/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.keycloak.token
                },
                body: JSON.stringify({
                    // id: this.configId,
                    primaryNumber: this.primaryNumber,
                    virtualNumber: this.virtualNumber,
                    from: this.from.toISOString(),
                    noOfDays: this.noOfDays,
                    renewing: this.renewing
                })
            }).then(res => res.json()).then(value => {
                console.log(value);
                if (value.status == "SUCCESSFUL") {
                    this.saving = false;
                    this.displayForm = false;
                    this.toast.add({
                        severity: 'success',
                        summary: 'Activating DialX number',
                        detail: 'Activating DialX number was succeeded.',
                        life: 3000
                    });
                    this.fetchVirtualNumberConfigList();
                } else {
                    console.log("FAIL-----------------");
                    this.mainError = value.message;
                    u
                    this.saving = false;
                    this.displayForm = false,
                        this.toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: value.message + ' Please contact support.',
                            life: 30000
                        });
                }
            }).catch(reason => {
                console.log(reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });
        },
        async update_status(configId, toast) {
            console.info("update_status");
            this.deactivating = true;
            this.mainError = "";
            fetch(BASE_URL + '/virtual_number_configurations/update_status/' + configId + "/DEACTIVATED_CONFIG_KEEP_NUMBER_LOCKED", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.keycloak.token
                },
            })
                .then(res => res.json())
                .then(value => {
                    console.log(value);
                    this.deactivating = false;
                    toast.add({
                        severity: 'success',
                        summary: 'Deactivation',
                        detail: 'Deactivation request success.',
                        life: 3000
                    });
                    this.fetchVirtualNumberConfigList();
                }).catch(reason => {
                console.log("catch reason")
                console.log(reason)
                this.toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact support.',
                    life: 30000
                });
            });

        },
        close() {
            this.displayForm = false;
            this.deactivating = false;
            this.mainError = "";
            this.saving = false;
        },
        closeMessage(id) {
            console.log(id)
        }
    },
});