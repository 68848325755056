let domain = window.location.host;
export const BASE_URL = (function () {
    console.info("domain|" + domain)
    let base = "http://localhost:8090";
    if (domain.includes("techcrmpsi")) {
        base = "https://scm.iq.techcrmpsi.tk";
    } else if (domain.includes("54.179.244.191")) {
        base = "http://54.179.244.191/api"
    } else if (domain.includes("dialx.ideabiz.lk")) {
        base = "https://dialx.ideabiz.lk/api"
    }
    return base;
})();

export const keycloak = (function () {
    let keycloakConfig = {
        "realm": "MarketPlace",
        "url": "https://keycloak.ideabiz.lk/auth/",
        "clientId": "dialog-iq-application-ui",
        "ssl-required": "external",
        "public-client": "true",
        "confidential-port": "0",
        "verify-token-audience": "true",
        "use-resource-role-mappings": "true"
    }
    if (domain.includes("dialx.ideabiz.lk")) {
        keycloakConfig = {
            "realm": "MarketPlace",
            "url": "https://marketplaceauth.dialog.lk/auth/",
            "clientId": "dialog-iq-application-ui",
            "ssl-required": "external",
            "public-client": "true",
            "confidential-port": "0",
            "verify-token-audience": "true",
            "use-resource-role-mappings": "true"
        }
    } else if (domain.includes("service-creation.iq.techcrmpsi.tk")) {
        keycloakConfig = {
            "realm": "MarketPlace",
            "url": "https://keycloak.ideabiz.lk/auth/",
            "clientId": "dialog-iq-application-ui",
            "ssl-required": "external",
            "public-client": "true",
            "confidential-port": "0",
            "verify-token-audience": "true",
            "use-resource-role-mappings": "true"
        }
    }
    console.log(keycloakConfig)
    return keycloakConfig;
})();

export const MIN_DATE_ADD = (function () {
    let base = 0;
    if (domain.includes("meetstg")) {
        base = 1;
    }
    return base;
})();

export const RECALL_NUMBER = '0117669369'