import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('./pages/VirtualNumbersList.vue'),
    },
    // {
    //     path: '/help-web',
    //     name: 'help-web',
    //     component: () => import('./pages/Help.vue')
    // }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
