import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';

import {createApp, reactive} from 'vue';
import router from './router';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';
import Keycloak from "keycloak-js";
import {createPinia} from 'pinia'
import {useStore} from "./store/Store";
import {keycloak} from "@/constants";

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    next();
});

const keycloakInstance = Keycloak(keycloak);


keycloakInstance.init({onLoad: 'login-required'}).then((auth) => {
        console.log(auth)
        if (!auth) {
            window.location.reload();
        } else {


            const app = createApp(App);

            app.config.globalProperties.$appState = reactive({theme: 'lara-light-indigo', darkTheme: false});

            app.use(PrimeVue, {ripple: true, inputStyle: 'outlined'});
            app.use(ConfirmationService);
            app.use(ToastService);
            app.use(router);
            app.use(createPinia())


            app.directive('tooltip', Tooltip);
            app.directive('ripple', Ripple);
            app.directive('code', CodeHighlight);
            app.directive('badge', BadgeDirective);
            app.directive('styleclass', StyleClass);

            app.component('Accordion', Accordion);
            app.component('AccordionTab', AccordionTab);
            app.component('AutoComplete', AutoComplete);
            app.component('Avatar', Avatar);
            app.component('AvatarGroup', AvatarGroup);
            app.component('Badge', Badge);
            app.component('Breadcrumb', Breadcrumb);
            app.component('Button', Button);
            app.component('Calendar', Calendar);
            app.component('Card', Card);
            app.component('Carousel', Carousel);
            app.component('Chart', Chart);
            app.component('Checkbox', Checkbox);
            app.component('Chip', Chip);
            app.component('Chips', Chips);
            app.component('ColorPicker', ColorPicker);
            app.component('Column', Column);
            app.component('ConfirmDialog', ConfirmDialog);
            app.component('ConfirmPopup', ConfirmPopup);
            app.component('ContextMenu', ContextMenu);
            app.component('DataTable', DataTable);
            app.component('DataView', DataView);
            app.component('DataViewLayoutOptions', DataViewLayoutOptions);
            app.component('Dialog', Dialog);
            app.component('DialogService', DialogService);
            app.component('DynamicDialog', DynamicDialog);
            app.component('Divider', Divider);
            app.component('Dropdown', Dropdown);
            app.component('Fieldset', Fieldset);
            app.component('FileUpload', FileUpload);
            app.component('InlineMessage', InlineMessage);
            app.component('Inplace', Inplace);
            app.component('InputMask', InputMask);
            app.component('InputNumber', InputNumber);
            app.component('InputSwitch', InputSwitch);
            app.component('InputText', InputText);
            app.component('Galleria', Galleria);
            app.component('Knob', Knob);
            app.component('Listbox', Listbox);
            app.component('MegaMenu', MegaMenu);
            app.component('Menu', Menu);
            app.component('Menubar', Menubar);
            app.component('Message', Message);
            app.component('MultiSelect', MultiSelect);
            app.component('OrderList', OrderList);
            app.component('OrganizationChart', OrganizationChart);
            app.component('OverlayPanel', OverlayPanel);
            app.component('Paginator', Paginator);
            app.component('Panel', Panel);
            app.component('PanelMenu', PanelMenu);
            app.component('Password', Password);
            app.component('PickList', PickList);
            app.component('ProgressBar', ProgressBar);
            app.component('RadioButton', RadioButton);
            app.component('Rating', Rating);
            app.component('SelectButton', SelectButton);
            app.component('ScrollPanel', ScrollPanel);
            app.component('ScrollTop', ScrollTop);
            app.component('Slider', Slider);
            app.component('Sidebar', Sidebar);
            app.component('Skeleton', Skeleton);
            app.component('SplitButton', SplitButton);
            app.component('Splitter', Splitter);
            app.component('SplitterPanel', SplitterPanel);
            app.component('Steps', Steps);
            app.component('TabMenu', TabMenu);
            app.component('TabView', TabView);
            app.component('TabPanel', TabPanel);
            app.component('Tag', Tag);
            app.component('Textarea', Textarea);
            app.component('TieredMenu', TieredMenu);
            app.component('Timeline', Timeline);
            app.component('Toast', Toast);
            app.component('Toolbar', Toolbar);
            app.component('ToggleButton', ToggleButton);
            app.component('Tree', Tree);
            app.component('TreeSelect', TreeSelect);
            app.component('TreeTable', TreeTable);
            app.component('TriStateCheckbox', TriStateCheckbox);

            app.component('BlockViewer', BlockViewer);

            app.mount('#app');
            const store = useStore()
            store.keycloak = keycloakInstance;

        }
    }
).catch((e) => {
    console.log(e)
});

// const toast = useToast();

keycloakInstance.onTokenExpired = () => {
    console.log('Before call updateToken')
    console.log('Code: ' + keycloakInstance.sessionId)

    // keycloakInstance.updateToken(10)
    //     .then((refreshed) => {
    //         if (refreshed) {
    //             console.log("Keycloak update token status: ", refreshed);
    //         } else {
    //             console.log("Keycloak update token status: ", refreshed);
    //         }
    //     }).catch(() => {
    //     console.error("Unalbe to refresh keyclock token");
    // });
    keycloakInstance.logout();
    console.log('After call updateToken')
}
//     console.log('token expired', keycloakInstance.token);
//     onFetchingToken = true;
// let data = new FormData();
// data.append('client_id', keycloak.clientId)
// data.append('grant_type', 'refresh_token')
// data.append('refresh_token', keycloakInstance.refreshToken)
// fetch(keycloak.url + '/realms/' + keycloak.realm + '/protocol/openid-connect/token', {
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded',
//         'Access-Control-Allow-Origin': '*',
//     },
    //     credentials: 'include',
    //     body: data
    // }).then(res => res.json()).then(value => {
    //     console.log(value);
    // });    // keycloakInstance.updateToken(300).then(function () {
    // if (refreshed) {
    //     console.log('Token refreshed' + refreshed);
    // console.log('Token refreshed');
// } else {
//     console.log('Token not refreshed, valid for '
//         + Math.round(keycloakInstance.tokenParsed.exp + keycloakInstance.timeSkew - new Date().getTime() / 1000) + ' seconds');
// }
// }).catch(function () {
//     console.log('Failed to refresh token');
//     router.go();
// });
// }

// let onFetchingToken = false;
// const {fetch: origFetch} = window;
// window.fetch = async (...args) => {
//     console.log("fetch called with args:", args);
//     const response = await origFetch(...args);
//     console.log("response")
//     console.log(response)
//     if( response.status>=400 && response.status < 500){
//         this.keycloak.logout();
//     }
/* work with the cloned response in a separate promise
   chain -- could use the same chain with `await`. */
// response
//     .clone()
//     .json()
//     .then(body => console.log("intercepted response:", body))
//     .catch(err => {
//         console.error(err)
//         // toast.add({
//         //     severity: 'success',
//         //     summary: 'Deactivation',
//         //     detail: 'Deactivation request success.',
//         //     life: 3000
//         // });
//     }).finally(() => console.log("@ finally in interceptor"))
// ;

/* the original response can be resolved unmodified: */
// return response;

/* or mock the response: */
// return {
//     ok: true,
//     status: 200,
//     json: async () => ({
//         userId: 1,
    //         id: 1,
    //         title: "Mocked!!",
    //         completed: false
    //     })
// };
// };

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}